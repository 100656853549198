import {
  Dropdown,
  DropdownContent,
  DropdownTrigger,
} from '~/ui/components/actions/Dropdown';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';
import { faVolumeHigh } from '@fortawesome/pro-solid-svg-icons/faVolumeHigh';
import { faVolumeMedium } from '@fortawesome/pro-solid-svg-icons/faVolumeMedium';
import { faVolumeLow } from '@fortawesome/pro-solid-svg-icons/faVolumeLow';
import { faVolumeMute } from '@fortawesome/pro-solid-svg-icons/faVolumeMute';
import { Range } from '~/ui/components/data-input/Range';
import { playerStore } from '~/players/stores/playerStore';
import { createSignal } from 'solid-js';

export const VolumeSlider = () => {
  const [icon, setIcon] = createSignal(faVolumeHigh);
  const handleChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = Number(target.value);
    switch (true) {
      case value === 1:
        setIcon(faVolumeMute);
        break;
      case value > 1 && value < 25:
        setIcon(faVolumeLow);
        break;
      case value >= 25 && value < 75:
        setIcon(faVolumeMedium);
        break;
      case value >= 75:
      default:
        setIcon(faVolumeHigh);
        break;
    }
    playerStore.volume = value;
  };
  return (
    <Dropdown>
      <DropdownTrigger variant={'link'} round size={'sm'} color={'secondary'}>
        <FontAwesomeIcon class={'h-4'} icon={icon()} />
      </DropdownTrigger>
      <DropdownContent class={'h-40'}>
        <Range
          class={'-rotate-180'}
          direction={'vertical'}
          color={'primary'}
          min='1'
          max='100'
          step='1'
          value={playerStore.volume}
          onInput={handleChange}
        />
      </DropdownContent>
    </Dropdown>
  );
};
