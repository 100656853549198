import { RevisionSelect } from '~/players/components/RevisionSelect';
import { PrimaryRevisionButton } from '~/revisions/components/PrimaryRevisionButton';
import { MasterVersionToggle } from '~/revisions/components/MasterVersionToggle';
import { OverflowMenu } from '~/tracks/components/OverflowMenu';
import { playerStore } from '~/players/stores/playerStore';

export const TrackControls = () => {
  return (
    <div class='hidden lg:flex justify-end items-center gap-1 mt-4 h-20 ml-auto flex-1'>
      <RevisionSelect />
      <PrimaryRevisionButton store={playerStore} />
      <MasterVersionToggle store={playerStore} />
      <OverflowMenu store={playerStore} />
    </div>
  );
};
