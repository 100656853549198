import { Select, SelectOption } from '~/ui/components/data-input/Select';
import { playerStore } from '~/players/stores/playerStore';
import { actions } from 'astro:actions';
import { createEffect, createResource, Index, on } from 'solid-js';
import { createSignal } from 'solid-js';

export const RevisionSelect = () => {
  const [value, setValue] = createSignal<string | undefined>();
  let localTrack: string | null = null;
  const [data, { refetch }] = createResource(async () => {
    if (!playerStore.track) {
      return;
    }
    const { data, error } = await actions.getTrackRevisions({
      id: playerStore.track.id,
    });

    if (!data || error) {
      return;
    }
    if (localTrack !== playerStore.track?.id) {
      localTrack = playerStore.track?.id;
      setValue(data?.find((r) => r.is_primary)?.id || data?.[0]?.id);
    }
    return data;
  });

  const handleChange = async (evt: Event) => {
    const target = evt.target as HTMLInputElement;
    const value = target.value;

    const revision = data()?.find((r) => r.id === value);
    if (!revision) {
      return;
    }

    playerStore.revision = revision.id;
    setValue(value);
  };

  createEffect(
    on(
      () => playerStore.revision,
      () => {
        playerStore.revision?.is_primary?.toString();
        if (!playerStore.track) {
          return;
        }
        refetch();
      },
    ),
  );

  return (
    <Select
      color={'secondary'}
      value={value()}
      class={'w-full md:w-48'}
      onChange={handleChange}
    >
      <SelectOption disabled>Select a Revision</SelectOption>
      <Index each={data()?.sort((a, b) => a.index - b.index)}>
        {(revision) => (
          <SelectOption value={revision().id} color={'secondary'}>
            {revision().name || `Revision #${revision().index + 1}`}
          </SelectOption>
        )}
      </Index>
    </Select>
  );
};
