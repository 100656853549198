import { Button, type ButtonProps } from '~/ui/components/actions/Button';
import { playerStore } from '~/players/stores/playerStore';
import { createSignal, createEffect, Show } from 'solid-js';

import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';
import { faPause } from '@fortawesome/pro-solid-svg-icons/faPause';
import { on } from 'solid-js';

export interface PlayButtonProps extends Omit<ButtonProps, 'children'> {
  showText?: boolean;
}

export const PlayButton = (props: PlayButtonProps) => {
  const [icon, setIcon] = createSignal(faPlay);
  const [text, setText] = createSignal('Play');
  const handleClick = async () => {
    playerStore.togglePlay();
  };

  createEffect(
    on(
      () => playerStore.paused,
      () => {
        setIcon(playerStore.paused ? faPlay : faPause);
        setText(playerStore.paused ? 'Play' : 'Pause');
      },
    ),
  );

  return (
    <Button {...props} onClick={handleClick}>
      <FontAwesomeIcon class={'h-5'} icon={icon()} />
      <Show when={props.showText}>{text()}</Show>
    </Button>
  );
};
