import Button from '~/ui/components/actions/Button';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';
import { faBackwardStep } from '@fortawesome/pro-solid-svg-icons/faBackwardStep';
import { cn } from '~/ui/methods/classNames';
import { playerStore } from '~/players/stores/playerStore';

export const PreviousTrackButton = () => {
  const handleClick = () => {
    if (!playerStore.tracks || !playerStore.track) {
      return;
    }
    const index = playerStore.tracks.findIndex(
      (track) => track.id === playerStore.track?.id,
    );
    if (index === 0) {
      return;
    }
    const track = playerStore.tracks[index - 1];
    playerStore.track = track.id;
  };
  return (
    <Button
      id='next-track-button'
      variant={'link'}
      color={'secondary'}
      size={'sm'}
      class={cn('px-0', 'w-8')}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faBackwardStep} class={'h-4'} />
    </Button>
  );
};
