import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faLinkSlash } from '@fortawesome/pro-solid-svg-icons/faLinkSlash';
import { playerStore } from '~/players/stores/playerStore';
import { projectStore } from '~/projects/stores/projectStore';
import Button from '~/ui/components/actions/Button';
import { Tooltip } from '~/ui/components/feedback/Tooltip';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';

export const SyncPlayersButton = () => {
  const handleClick = () => {
    if (playerStore.synchronized) {
      playerStore.synchronized = false;
      return;
    }
    if (projectStore.project?.id) {
      playerStore.project = projectStore.project?.id;
    }
    if (projectStore.track?.id) {
      playerStore.track = projectStore.track?.id;
    }
    if (projectStore.revision?.id) {
      playerStore.revision = projectStore.revision?.id;
    }
    playerStore.synchronized = true;
  };

  return (
    <Tooltip
      tip={
        playerStore.synchronized
          ? 'Detach player from project page'
          : 'Attach player to project page'
      }
    >
      <Button
        round
        color='secondary'
        variant={'link'}
        size={'sm'}
        onClick={handleClick}
      >
        <FontAwesomeIcon
          class={'h-4'}
          icon={playerStore.synchronized ? faLink : faLinkSlash}
        />
      </Button>
    </Tooltip>
  );
};
