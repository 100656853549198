import { cn } from '~/ui/methods/classNames';
import { PlayButton } from '~/players/components/PlayButton';
import { RewindButton } from '~/players/components/RewindButton';
import { PreviousTrackButton } from '~/players/components/PreviousTrackButton';
import { NextTrackButton } from '~/players/components/NextTrackButton';
import { RepeatButton } from '~/players/components/RepeatButton';
import { Range } from '~/ui/components/data-input/Range';
import { playerStore } from '~/players/stores/playerStore';
import { formatPlaytime } from '~/common/methods/formatPlaytime';
import { VolumeSlider } from '~/players/components/VolumeSlider';
import { SyncPlayersButton } from '~/players/components/SyncPlayersButton';

export const Transport = () => (
  <div
    class={cn(
      'max-w-lg',
      'w-full',
      'justify-end',
      'lg:justify-center',
      'h-24',
      'flex-1',
      'flex',
      'flex-col',
      'gap-1',
    )}
  >
    <div class={cn('flex', 'gap-4', 'justify-center', 'items-center')}>
      <SyncPlayersButton />
      <RewindButton />
      <PreviousTrackButton />
      <PlayButton
        color={'secondary'}
        class={cn('w-14', 'h-14', 'rounded-full', 'after:rounded-full')}
      />
      <NextTrackButton />
      <RepeatButton />
      <VolumeSlider />
    </div>
    <div class={cn('flex', 'gap-4')}>
      <p class={cn('text-sm', 'text-base-content', 'w-12', 'text-right')}>
        {formatPlaytime(playerStore.currentTime)}
      </p>
      <Range
        color='secondary'
        max={playerStore.duration}
        value={playerStore.currentTime}
        step={0.1}
        onMouseDown={() => (playerStore.seeking = true)}
        onMouseUp={() => (playerStore.seeking = false)}
        onChange={(e) => {
          if (!playerStore.audioElement) {
            return;
          }
          playerStore.currentTime = parseInt(e.target.value, 10);
          playerStore.audioElement.currentTime = playerStore.currentTime;
        }}
        min={0}
      />
      <p
        id='end-time'
        class={cn('text-sm', 'text-base-content', 'w-12', 'text-left')}
      >
        {formatPlaytime(playerStore.duration)}
      </p>
    </div>
  </div>
);
