import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faClose } from '@fortawesome/pro-regular-svg-icons/faClose';
import Button from '~/ui/components/actions/Button';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';
import { playerStore } from '~/players/stores/playerStore';
import { cn } from '~/ui/methods/classNames';
import { awaitAnimations } from '~/common/methods/awaitAnimations';

export const HideControls = () => {
  const handleVisible = () => {
    playerStore.isExpanded = !playerStore.isExpanded;
  };

  const handleClose = () => {
    playerStore.reset();

    awaitAnimations().then(() => {
      playerStore.empty();
    });
  };

  return (
    <div class={'absolute top-1 right-1'}>
      <Button
        class={'lg:hidden'}
        onClick={handleVisible}
        size={'sm'}
        round
        variant={'link'}
      >
        <FontAwesomeIcon
          class={cn('h-4 transition-transform', {
            '-rotate-180': !playerStore.isExpanded,
          })}
          icon={faChevronDown}
        />
      </Button>
      <Button onClick={handleClose} size={'sm'} round variant={'link'}>
        <FontAwesomeIcon class={'h-4'} icon={faClose} />
      </Button>
    </div>
  );
};
