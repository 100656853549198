import { createSignal } from 'solid-js';
import { on } from 'solid-js';
import { createEffect, Show } from 'solid-js';
import { awaitAnimations } from '~/common/methods/awaitAnimations';
import { playerStore } from '~/players/stores/playerStore';
import { ProjectArtwork } from '~/projects/components/ProjectArtwork';
import { Link } from '~/ui/components/navigation/Link';
import { cn } from '~/ui/methods/classNames';

export const TrackDetails = () => {
  let ref: HTMLDivElement | undefined;
  const [data, _setData] = createSignal<{
    trackTitle: string;
    projectTitle: string;
    artist?: string;
    artwork?: string;
  } | null>(null);

  const setData = () => {
    _setData({
      trackTitle: playerStore.track?.title || '',
      projectTitle: playerStore?.project?.title || '',
      artist: playerStore?.project?.artist || '',
      artwork: playerStore?.project?.artwork || '',
    });
  };

  createEffect(
    on(
      () => playerStore.track?.id,
      () => {
        if (!ref) {
          return;
        }
        if (!data()) {
          setData();
          return;
        }
        ref.classList.remove('xyz-in');
        ref.classList.add('xyz-out');
        awaitAnimations([ref]).then(() => {
          if (!playerStore.track) {
            return;
          }
          setData();
          ref.classList.add('xyz-in');
          ref.classList.remove('xyz-out');
        });
      },
      { defer: true },
    ),
  );

  return (
    <div
      xyz='fade'
      ref={ref}
      class={cn(
        'xyz-in',
        'track-details',
        'transition-all',
        'overflow-hidden',
        'flex',
        'gap-2',
        'items-center',
        'xyz-disable-out',
        'flex-1',
        {
          'h-24': playerStore.isExpanded,
          'h-0 flex-none p-0': !playerStore.isExpanded,
        },
      )}
    >
      <Show when={data()}>
        {(data) => (
          <>
            <ProjectArtwork
              artwork={data()?.artwork}
              class={
                'w-20 rounded-md shadow-none before:rounded-md after:rounded-md'
              }
              disableViewTransition
            />
            <div class='flex flex-col gap-1'>
              <h3 class='whitespace-nowrap text-ellipsis text-2xl leading-6 font-medium'>
                <Link
                  href={`/projects/${playerStore.project?.id}?track=${playerStore.track?.id}`}
                >
                  {data()?.trackTitle}
                </Link>
              </h3>
              <h4 class='whitespace-nowrap text-ellipsis text-lg leading-5 font-semibold text-base-content/50'>
                <Link href={`/projects/${playerStore.project?.id}`}>
                  {data()?.projectTitle}
                </Link>
              </h4>
              <h4 class='whitespace-nowrap text-ellipsis text-lg leading-5 font-semibold text-base-content/50'>
                {data()?.artist}
              </h4>
            </div>
          </>
        )}
      </Show>
    </div>
  );
};
