import Button from '~/ui/components/actions/Button';
import { cn } from '~/ui/methods/classNames';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';
import { faClockRotateLeft } from '@fortawesome/pro-solid-svg-icons/faClockRotateLeft';
import { playerStore } from '~/players/stores/playerStore';

export const RewindButton = () => (
  <Button
    id='rewind-button'
    variant={'link'}
    color={'secondary'}
    size={'sm'}
    class={cn('[&>svg]:text-text-primary', '[&>svg]:h-4', 'px-0', 'w-8')}
    onClick={() => {
      playerStore.currentTime = Math.max(playerStore.currentTime - 10, 0);
    }}
  >
    <FontAwesomeIcon icon={faClockRotateLeft} />
  </Button>
);
