import Button from '~/ui/components/actions/Button';
import { cn } from '~/ui/methods/classNames';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';
import { faRepeat1 } from '@fortawesome/pro-solid-svg-icons/faRepeat1';
import { playerStore } from '~/players/stores/playerStore';

// const sharedClasse/s = cn('[&>svg]:h-5', 'px-0', 'w-8');

export const RepeatButton = () => (
  <Button
    id='repeat-button'
    variant={'link'}
    round
    color={'secondary'}
    class={cn({ '[&>svg]:text-secondary': playerStore.repeating })}
    onClick={() => (playerStore.repeating = !playerStore.repeating)}
    size={'sm'}
  >
    <FontAwesomeIcon class={'h-4'} icon={faRepeat1} />
  </Button>
);
